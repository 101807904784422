import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Attributes, Table, Expander, List, Row } from './components';
export const error2147467259Options = [{
  param: 'Message missing required fields: MissingField',
  value: 'A required param is missing in the body.'
}, {
  param: 'Value does not fall within the expected range',
  value: 'Using a value that the API is not expecting.'
}, {
  param: 'Input was not in a correct format',
  value: 'Wrong format for body request.'
}, {
  param: 'Expected a value',
  value: 'Value missing.'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  error2147467259Options,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "errors"
    }}>{`Errors`}</h1>
    <p>{`If a request is not done properly or is missing some of the required parameters the API will prompt the user with a describing error of what went wrong.`}</p>
    <br />
    <h2 {...{
      "id": "the-error-object"
    }}>{`The error object`}</h2>
    <Row $objectRow mdxType="Row">
    <Expander title="Attributes" mdxType="Expander">
        <Attributes items={[{
          name: 'success',
          type: 'boolean',
          description: 'Result of the request executed. Will show false on errors.'
        }, {
          name: 'error_code',
          type: 'number',
          description: 'Integer identifier for the error.'
        }, {
          name: 'error_text',
          type: 'string',
          description: 'Description of the error.'
        }, {
          name: 'instance_guid',
          type: 'string',
          description: 'UUID related to the error.'
        }, {
          name: 'status_code',
          type: 'number',
          description: 'HTTP status of the error.'
        }]} mdxType="Attributes" />
    </Expander>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example of an error response:

{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "e3159f1f-eab9-4fa8-8605-447dafc14ba1",
        "status_code": 404
    }
}
`}</code></pre>
    </Row>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "error-codes"
    }}>{`Error codes`}</h2>

    <Attributes items={[{
      name: '-520093684',
      type: 'HTTP 404',
      description: 'Object Not Found.'
    }, {
      name: '-1593835519',
      type: 'HTTP 401',
      description: 'Related to authorization. Usually auth token is not valid.'
    }, {
      name: '-1593835510',
      type: 'HTTP 500',
      description: 'Object already exists'
    }, {
      name: '-519962545',
      type: 'HTTP 500',
      description: 'Trying to add more employees (entity) than the account limit allows.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "the--2147467259-error"
    }}>{`The -2147467259 error`}</h2>
    <p>{`The -2147467259 error is related to errors in the body of a request and implies multiple possible causes for it:`}</p>
    <br />
    <List values={[{
      title: 'Message missing required fields: {MissingField}.',
      value: 'A required param is missing in the request.'
    }, {
      title: 'Value does not fall within the expected range.',
      value: 'Using a value that the API is not expecting. Maybe out of the range expected or a different type.'
    }, {
      title: 'Input was not in a correct format.',
      value: 'Wrong format on the request body.'
    }, {
      title: 'Expected a value.',
      value: 'Value missing in the request.'
    }, {
      title: '(1:0) error: Unexpected token EOF, expected: "{".',
      value: 'Expected a body in the request that was not specified.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      